





































































import { Mutations } from "@/models/enums/mutations.enum";
import { DEFAULT_DATE_FORMAT } from "@/models/constant/date.constant";
import Vue from "vue";
export default Vue.extend({
  name: "employeeDetails",
  props: ["employeeNumber", "dataDepartment"],
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      selectedRadio: this.$store.state.contactStore.dataEmployee.gender,
      formRules: {
        nik: {
          label: this.$t("lbl_nik"),
          name: "nik",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "nik",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        gender: {
          label: this.$t("lbl_gender"),
          name: "gender",
          decorator: [
            "gender",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        // role -> departement
        role: {
          label: this.$t("lbl_department"),
          name: "role",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "role",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        dateOfBirth: {
          label: this.$t("lbl_date_of_birth"),
          name: "dateOfBirth",
          placeholder: "",
          decorator: [
            "dateOfBirth",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        startingDate: {
          label: this.$t("lbl_starting_date"),
          name: "startingDate",
          placeholder: "",
          decorator: [
            "startingDate",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        employeeNumber: {
          label: this.$t("lbl_employee_number"),
        },
      },
    };
  },
  methods: {
    handleChangeGender(event) {
      const payload = {
        ...this.$store.state.contactStore.dataEmployee,
        gender: event.target.value,
      };
      this.$store.commit(
        `contactStore/${Mutations.SET_DATA_EMPLOYEE}`,
        payload
      );
    },
    handleChangeDateOfBirth(value, dateString) {
      // value in moment, datestring in string
      const payload = {
        ...this.$store.state.contactStore.dataEmployee,
        dateOfBirth: dateString,
      };
      this.$store.commit(
        `contactStore/${Mutations.SET_DATA_EMPLOYEE}`,
        payload
      );
    },
    handleChangeStartingDate(value, dateString) {
      const payload = {
        ...this.$store.state.contactStore.dataEmployee,
        startDate: dateString,
      };
      this.$store.commit(
        `contactStore/${Mutations.SET_DATA_EMPLOYEE}`,
        payload
      );
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  computed: {
    computedDataDepartment() {
      return this.dataDepartment;
    },
  },
});
